import { BaseService, Service } from "/@/cool";
@Service("deviceController")
class DeviceController extends BaseService {
	constructor() {
		super();
		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["allow"] = prefix + "allow";
	}
}
export default DeviceController;
